// import { parse } from 'worktop/cookie';
const ATTRS = new Set([
  'domain',
  'path',
  'max-age',
  'expires',
  'samesite',
  'secure',
  'httponly',
]);
export interface Attributes {
  maxage?: number;
  expires?: Date;
  samesite?: 'Lax' | 'Strict' | 'None';
  secure?: boolean;
  httponly?: boolean;
  domain?: string;
  path?: string;
}
type Cookie = Attributes & Record<string, string>;

export function parse(cookie: string): Cookie {
  let out: Cookie = {},
    idx: number,
    tmp: string;
  let i = 0,
    arr = cookie.split(/;\s*/g);
  let key: string, val: string;

  for (; i < arr.length; i++) {
    tmp = arr[i];
    idx = tmp.indexOf('=');

    if (!!~idx) {
      key = tmp.substring(0, idx++).trim();
      val = tmp.substring(idx).trim();
      if (val[0] === '"') {
        val = val.substring(1, val.length - 1);
      }
      if (!!~val.indexOf('%')) {
        try {
          val = decodeURIComponent(val);
        } catch (err) {
          /* ignore */
        }
      }
      if (ATTRS.has((tmp = key.toLowerCase()))) {
        if (tmp === 'expires') out.expires = new Date(val);
        else if (tmp === 'max-age') out.maxage = +val;
        else out[tmp] = val;
      } else {
        out[key] = val;
      }
    } else if ((key = tmp.trim().toLowerCase())) {
      if (key === 'httponly' || key === 'secure') {
        out[key] = true;
      }
    }
  }

  return out;
}

import { ShopifyCookies } from './analytics-types.js';
import { SHOPIFY_Y, SHOPIFY_S } from './cart-constants';

const tokenHash = 'xxxx-4xxx-xxxx-xxxxxxxxxxxx';

export function buildUUID(): string {
  let hash = '';

  try {
    const crypto: Crypto = window.crypto;
    const randomValuesArray = new Uint16Array(31);
    crypto.getRandomValues(randomValuesArray);

    // Generate a strong UUID
    let i = 0;
    hash = tokenHash
      .replace(/[x]/g, (c: string): string => {
        const r = randomValuesArray[i] % 16;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        i++;
        return v.toString(16);
      })
      .toUpperCase();
  } catch (err) {
    // crypto not available, generate weak UUID
    hash = tokenHash
      .replace(/[x]/g, (c: string): string => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      })
      .toUpperCase();
  }

  return `${hexTime()}-${hash}`;
}

export function hexTime(): string {
  // 32 bit representations of new Date().getTime() and performance.now()
  let dateNumber = 0;
  let perfNumber = 0;

  // Result of zero-fill right shift is always positive
  dateNumber = new Date().getTime() >>> 0;

  try {
    perfNumber = performance.now() >>> 0;
  } catch (err) {
    perfNumber = 0;
  }

  const output = Math.abs(dateNumber + perfNumber)
    .toString(16)
    .toLowerCase();

  // Ensure the output is exactly 8 characters
  return output.padStart(8, '0');
}

export function getShopifyCookies(cookies: string): ShopifyCookies {
  const cookieData = parse(cookies);
  return {
    [SHOPIFY_Y]: cookieData[SHOPIFY_Y] || '',
    [SHOPIFY_S]: cookieData[SHOPIFY_S] || '',
  };
}
