import { Injectable } from '@angular/core';
import { AddToCartItem, Cart, Collection, Product } from '../classes/utility';
import { EnvironmentService } from './environment.service';
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(private environmentService: EnvironmentService) {}

  startNDISCheckout(cart: Cart) {
    try {
      const currency = cart.cost.totalAmount.currencyCode;
      const totalPrice: string = cart.cost.totalAmount.amount;
      const items: {
        id: string;
        name: string;
        vendor: string;
        category: string[];
        price: string;
        variant_title: string;
        quantity: number;
      }[] = cart.lines?.edges.map((x: any) => {
        return {
          id: x.node.merchandise.product.id,
          name: x.node.merchandise.product.title,
          vendor: x.node.merchandise.product.vendor,
          variant_title: x.node.merchandise.title,
          category: x.node.merchandise.product.tags,
          price: x.node.merchandise.priceV2.amount,
          quantity: x.node.quantity,
        };
      });
      if (!this.environmentService.isBrowser()) return;
      let window = this.environmentService.getWindow()! as Window;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'submit_application',
        ecommerce: {
          currency: currency,
          value: totalPrice,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.vendor,
              item_variant: item.variant_title,
              item_category: item.category || 'default',
              price: item.price,
              quantity: item.quantity,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  viewProduct(product: Product) {
    try {
      const currency = 'AUD';
      const price = product.min_price.toFixed(2);
      const items = [
        {
          id: product.id,
          name: product.title,
          vendor: product.vendor,
          category: ['', ''],
          price: product.min_price.toFixed(2),
        },
      ];
      if (!this.environmentService.isBrowser()) return;
      let window = this.environmentService.getWindow()! as Window;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'view_item',
        ecommerce: {
          currency: currency,
          value: price,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.vendor,
              item_category: item.category[0] || undefined,
              item_category2: item.category[1] || undefined,
              price: item.price,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  addToCart(variants: AddToCartItem[], cart: Cart) {
    try {
      const currency = cart.cost.totalAmount.currencyCode;
      const price = cart.lines.edges
        .filter((x) =>
          variants
            .map((y) => y.variantId)
            .find((z) => z == x.node.merchandise.id)
        )
        .reduce(
          (acc, x) => acc + parseFloat(x.node.merchandise.priceV2.amount),
          0
        );

      const items = cart.lines.edges
        .filter((x) =>
          variants
            .map((y) => y.variantId)
            .find((z) => z == x.node.merchandise.id)
        )
        .map((x) => {
          return {
            id: x.node.merchandise.product.id,
            name: x.node.merchandise.product.title,
            vendor: x.node.merchandise.product.vendor,
            variant_title: x.node.merchandise.title,
            category: x.node.merchandise.product.tags,
            price: x.node.merchandise.priceV2.amount,
            quantity: x.node.quantity,
          };
        });
      if (!this.environmentService.isBrowser()) return;
      let window = this.environmentService.getWindow()! as Window;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'add_to_cart',
        ecommerce: {
          currency: currency,
          value: price,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.vendor,
              item_variant: item.variant_title,
              item_category: item.category || 'default',
              price: item.price,
              quantity: item.quantity,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  viewCart(cart: Cart) {
    try {
      const currency = cart.cost.totalAmount.currencyCode;
      const totalPrice: string = cart.cost.totalAmount.amount;
      const items: {
        id: string;
        name: string;
        vendor: string;
        category: string[];
        price: string;
        variant_title: string;
        quantity: number;
      }[] = cart.lines?.edges.map((x: any) => {
        return {
          id: x.node.merchandise.product.id,
          name: x.node.merchandise.product.title,
          vendor: x.node.merchandise.product.vendor,
          variant_title: x.node.merchandise.title,
          category: x.node.merchandise.product.tags,
          price: x.node.merchandise.priceV2.amount,
          quantity: x.node.quantity,
        };
      });
      if (!this.environmentService.isBrowser()) return;
      let window = this.environmentService.getWindow()! as Window;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'view_cart',
        ecommerce: {
          currency: currency,
          value: totalPrice,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.vendor,
              item_variant: item.variant_title,
              item_category: item.category || 'default',
              price: item.price,
              quantity: item.quantity,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  viewCollection(collection: Collection) {
    try {
      const collectionId = collection.id;
      const collectionName = collection.title;
      const items: {
        id: string;
        name: string;
        vendor: string;
        category: string[];
        price: string;
      }[] = collection.products.map((x: any) => {
        return {
          id: x.id,
          name: x.title,
          vendor: x.vendor,
          category: [collection.title],
          price: x.min_price.toFixed(2),
        };
      });
      if (!this.environmentService.isBrowser()) return;
      let window = this.environmentService.getWindow()! as Window;
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ ecommerce: null });
      window['dataLayer'].push({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: collectionId,
          item_list_name: collectionName,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.vendor,
              item_category: collectionName,
              price: item.price,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
}
