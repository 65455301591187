import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './localstorage.service';
import { MercuryService } from './mercury.service';
import { SEOService } from './seo.service';
import { SessionStorageService } from './sessionstorage.service';
import { ShopifyService } from './shopify.service';
import { StoryBlokService } from './storyblok.service';
import { ThemeService } from './theme.service';

export default [
  StoryBlokService,
  LocalStorageService,
  SessionStorageService,
  SEOService,
  EnvironmentService,
  ShopifyService,
  ThemeService,
  MercuryService,
];
