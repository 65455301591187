import { HttpClient } from '@angular/common/http';
import { afterNextRender, Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';
import { LocalStorageService } from '../localstorage.service';
import { AnalyticsEventName, ShopifySalesChannel } from './analytics-constants';
import {
  addToCart as customerAddToCart,
  collectionView as customerCollectionView,
  pageView as customerPageView,
  pageView2 as customerPageView2,
  productView as customerProductView,
  searchView as customerSearchView,
} from './analytics-schema-custom-storefront-customer-tracking';
import { pageView as trekkiePageView } from './analytics-schema-trekkie-storefront-page-view';
import {
  ClientBrowserParameters,
  ShopifyAddToCartPayload,
  ShopifyAnalytics,
  ShopifyAnalyticsProduct,
  ShopifyMonorailEvent,
  ShopifyPageViewPayload,
} from './analytics-types';
import { errorIfServer } from './analytics-utils';
import { SHOPIFY_S, SHOPIFY_Y } from './cart-constants';
import { getShopifyCookies } from './cookies-utils';
import { ShopifyCookiesService } from './useShopifyCookies';
import { AddToCartItem, Cart } from 'src/app/classes/utility';

type MonorailResponse = {
  status: number;
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class ShopifyAnalyticsService {
  private readonly ERROR_MESSAGE =
    'sendShopifyAnalytics request is unsuccessful';

  constructor(
    private http: HttpClient,
    private shopifyCookiesService: ShopifyCookiesService,
    private localStorage: LocalStorageService,
    private environmentService: EnvironmentService
  ) {
    afterNextRender(() => {
      this.shopifyCookiesService.useShopifyCookies({
        hasUserConsent: true,
      });
    });
  }

  viewPage(page: any) {
    if (!this.environmentService.isBrowser()) return;
    this.sendShopifyAnalytics(
      {
        eventName: AnalyticsEventName.PAGE_VIEW,
        payload: {
          cartId: this.localStorage.getItem('cartId')!,
          hasUserConsent: true,
          shopifySalesChannel: ShopifySalesChannel.headless,
          shopId: 'gid://shopify/Shop/9607794',
          currency: 'AUD',
          ...this.getClientBrowserParameters(),
        },
      },
      'shop.dailyorders.com.au'
    );
  }

  addToCart(addToCartItems: AddToCartItem[], cart: Cart) {
    if (!this.environmentService.isBrowser()) return;
    this.sendShopifyAnalytics(
      {
        eventName: AnalyticsEventName.ADD_TO_CART,
        payload: {
          cartId: this.localStorage.getItem('cartId')!,
          hasUserConsent: true,
          shopifySalesChannel: ShopifySalesChannel.headless,
          shopId: 'gid://shopify/Shop/9607794',
          currency: 'AUD',
          products: addToCartItems
            .map((x) => {
              const cartItem = cart.lines.edges.find(
                (y) => y.node.merchandise.id == x.variantId
              );
              if (!cartItem) return;
              return {
                productGid:
                  'gid://shopify/Product/' +
                  cartItem.node.merchandise.product.id,
                name: cartItem.node.merchandise.product.title,
                variantName: cartItem.node.merchandise.title,
                brand: cartItem.node.merchandise.product.vendor,
                price: cartItem.node.merchandise.priceV2.amount,
                quantity: cartItem.node.quantity,
                variantGid: x.variantId,
              } as ShopifyAnalyticsProduct;
            })
            .filter((x) => x != undefined) as ShopifyAnalyticsProduct[],
          ...this.getClientBrowserParameters(),
        },
      },
      'shop.dailyorders.com.au'
    );
  }

  sendShopifyAnalytics(
    event: ShopifyAnalytics,
    shopDomain?: string
  ): Promise<void> {
    if (!this.environmentService.isBrowser()) Promise.resolve();
    const { eventName, payload } = event;
    if (!payload.hasUserConsent) return Promise.resolve();

    let events: ShopifyMonorailEvent[] = [];
    const pageViewPayload = payload as ShopifyPageViewPayload;

    switch (eventName) {
      case AnalyticsEventName.PAGE_VIEW:
        events.push(
          ...trekkiePageView(pageViewPayload),
          ...customerPageView(pageViewPayload)
        );
        break;
      case AnalyticsEventName.ADD_TO_CART:
        events.push(...customerAddToCart(payload as ShopifyAddToCartPayload));
        break;
      case AnalyticsEventName.PAGE_VIEW_2:
        events.push(
          ...trekkiePageView(pageViewPayload),
          ...customerPageView2(pageViewPayload)
        );
        break;
      case AnalyticsEventName.COLLECTION_VIEW:
        events.push(...customerCollectionView(pageViewPayload));
        break;
      case AnalyticsEventName.PRODUCT_VIEW:
        events.push(...customerProductView(pageViewPayload));
        break;
      case AnalyticsEventName.SEARCH_VIEW:
        events.push(...customerSearchView(pageViewPayload));
        break;
    }

    if (events.length) {
      return this.sendToShopify(events, shopDomain);
    } else {
      return Promise.resolve();
    }
  }

  private sendToShopify(
    events: ShopifyMonorailEvent[],
    shopDomain?: string
  ): Promise<void> {
    try {
      const eventsToBeSent = {
        events,
        metadata: {
          event_sent_at_ms: Date.now(),
        },
      };

      const url = shopDomain
        ? `https://${shopDomain}/.well-known/shopify/monorail/unstable/produce_batch`
        : 'https://monorail-edge.shopifysvc.com/unstable/produce_batch';

      return this.http
        .post(url, eventsToBeSent, {
          headers: { 'Content-Type': 'text/plain' },
          responseType: 'text',
        })
        .toPromise()
        .then((data: any) => {
          try {
            const jsonResponse = JSON.parse(data);
            jsonResponse.result.forEach((eventResponse: MonorailResponse) => {
              if (eventResponse.status !== 200) {
                console.error(
                  this.ERROR_MESSAGE,
                  '\n\n',
                  eventResponse.message
                );
              }
            });
          } catch (err) {}
        })
        .catch((err) => {
          console.error(this.ERROR_MESSAGE, err);
        });
    } catch (err) {
      console.log(err);
      return Promise.resolve();
    }
  }

  getClientBrowserParameters(): ClientBrowserParameters {
    if (errorIfServer('getClientBrowserParameters')) {
      return {
        uniqueToken: '',
        visitToken: '',
        url: '',
        path: '',
        search: '',
        referrer: '',
        title: '',
        userAgent: '',
        navigationType: '',
        navigationApi: '',
      };
    }

    const [navigationType, navigationApi] = this.getNavigationType();
    const cookies = getShopifyCookies(document.cookie);

    return {
      uniqueToken: cookies[SHOPIFY_Y],
      visitToken: cookies[SHOPIFY_S],
      url: location.href,
      path: location.pathname,
      search: location.search,
      referrer: document.referrer,
      title: document.title,
      userAgent: navigator.userAgent,
      navigationType,
      navigationApi,
    };
  }

  private getNavigationType(): [string, string] {
    try {
      let navApi = 'PerformanceNavigationTiming';
      let navType = this.getNavigationTypeExperimental();
      if (!navType) {
        navType = this.getNavigationTypeLegacy();
        navApi = 'performance.navigation';
      }
      if (navType) {
        return [navType, navApi];
      } else {
        return ['unknown', 'unknown'];
      }
    } catch (err) {
      return ['error', 'error'];
    }
  }

  private getNavigationTypeExperimental(): string | undefined {
    try {
      const navigationEntries =
        performance?.getEntriesByType &&
        performance?.getEntriesByType('navigation');
      if (navigationEntries && navigationEntries[0]) {
        const rawType = (
          window.performance.getEntriesByType(
            'navigation'
          )[0] as PerformanceNavigationTiming
        )['type'];
        const navType = rawType && rawType.toString();
        return navType;
      }
    } catch (err) {
      return undefined;
    }
    return undefined;
  }

  private getNavigationTypeLegacy(): string | undefined {
    try {
      if (
        PerformanceNavigation &&
        performance?.navigation?.type !== null &&
        performance?.navigation?.type !== undefined
      ) {
        const rawType = performance.navigation.type;
        switch (rawType) {
          case PerformanceNavigation.TYPE_NAVIGATE:
            return 'navigate';
          case PerformanceNavigation.TYPE_RELOAD:
            return 'reload';
          case PerformanceNavigation.TYPE_BACK_FORWARD:
            return 'back_forward';
          default:
            return `unknown: ${rawType}`;
        }
      }
    } catch (err) {
      return undefined;
    }
    return undefined;
  }
}
